
@media(max-width: 768px){
    #TechName{
        font-size: 28px;
    }
    #iconSize{
        width: 0.75 em;
        height: 0.75 em;
        padding: 2px
    }
    #fooText{
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    #TechName{
        font-size: 34px;
    }
    #iconSize{
        width: 1 em;
        height: 1 em;
        padding: 4px
    }
}

@media (min-width: 1025px){
    #TechName{
        font-size: 56px;
    }
    #iconSize{
        width: 1 em;
        height: 1 em;
        padding: 8px
    }
}